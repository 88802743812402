import * as React from "react"
import Layout from "../components/layout"
import Container from "../components/container"
import NewsPageStyle from "../less/news-page.module.less"
import HeroImage from "../images/news.png"
import { graphql } from "gatsby"

import useIntl from "../helpers/useIntl"
import TranslateHtml from "../components/translateHtml"

const NewsItem = ({ data }) => {
  const intl = useIntl()

  const newsItem = data.newsItem

  return (
    <Layout heroImage={HeroImage}
            heroTextColor={"white"}
            heroSize={"large"}
            heroTitle={intl.translate(newsItem, "title")}
            heroSubtitle={"www.waarininversterenze.be"}>
      <Container size={"small"}>
        <div className={NewsPageStyle.detail}>
          <article className={NewsPageStyle.newsInfo}>
            <TranslateHtml object={newsItem} prop={"body"}/>
          </article>
        </div>
      </Container>
    </Layout>
  )
}

export default NewsItem

export const query = graphql`
  query($slug: String!) {
    newsItem(slug: {eq: $slug}) {
      id
      slug
      title_fr
      title_nl
      body_fr
      body_nl
    }
  }
`
